<template>
  <div class="container">
    <svg viewBox="0 0 266 308" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.4" d="M215.68 236.113C202.585 311.07 157.888 312.76 104.738 303.475C51.588 294.19 10.111 277.446 23.206 202.489C36.3 127.531 99.007 22.745 152.158 32.03C167.849 34.771 196.927 -1.03099 239.213 56.977C241.021 59.457 165.406 24.519 195.695 73.501C217.013 117.376 224.337 186.554 215.68 236.113Z" fill="#F5E5D6"/>
      <path opacity="0.4" d="M206.519 217.899C197.119 271.707 175.7 294.09 140.271 291.713C135.309 273.939 117.766 278.341 109.384 276.877C102.532 275.68 88.0509 267.14 76.1659 279.582C39.7439 270.188 28.2219 244.424 37.9979 188.459C49.4629 122.831 104.366 31.086 150.902 39.215C197.438 47.346 217.984 152.271 206.519 217.899Z" fill="#F5E5D6"/>
      <path d="M144.815 40.194C140.791 18.727 162.152 -7.341 199.873 2.093C237.595 11.527 255.617 61.843 239.78 94.749C239.78 94.749 239.779 47.937 216.667 37.736C193.555 27.536 159.281 25.815 160.031 45.856L144.815 40.194Z" fill="#BD6D2B"/>
      <path opacity="0.3" d="M165.958 34.26C158.728 30.802 152.109 25.92 148.381 19.64C144.828 26.101 143.538 33.378 144.815 40.195L160.032 45.857C159.833 40.544 162.095 36.761 165.958 34.26Z" fill="#E49557"/>
      <path d="M232.769 26.163C230.822 25.74 229.057 23.93 227.498 22.917C224.943 21.256 216.138 17.491 215.46 22.978C215.162 25.392 217.892 27.282 216.658 29.755C215.795 31.484 213.437 32.102 211.855 32.918C210.888 33.417 209.806 33.993 208.994 34.795C211.641 35.67 214.218 36.655 216.668 37.737C239.78 47.937 239.781 94.75 239.781 94.75C249.798 73.934 246.263 46.156 232.769 26.163Z" fill="#F7EFE9"/>
      <path d="M86.9759 262.903C85.4529 268.845 83.1789 274.796 79.7979 279.311C69.5589 292.998 58.3899 282.987 58.3899 282.987C48.6309 283.681 48.2839 278.25 48.2839 278.25C42.1139 277.278 44.0099 265.917 44.0099 265.917C44.0099 265.917 41.0829 265.053 42.3469 251.412C42.8459 245.979 49.2419 241.524 56.1349 235.127C58.8479 232.62 61.6319 229.809 64.1709 226.531L90.1409 238.836C90.1409 238.836 90.0939 250.856 86.9759 262.903Z" fill="#F7EFE9"/>
      <path opacity="0.3" d="M77.906 278.31C75.449 280.139 72.848 282.082 69.759 282.635C66.892 283.148 63.279 282.464 60.738 281.054C60.184 280.747 59.654 280.395 59.184 279.97C58.975 279.781 58.694 279.325 58.439 279.172C55.79 277.575 57.286 279.046 56.217 279.236C55.225 279.413 54.598 279.74 53.529 279.438C52.139 279.044 51.189 277.933 50.14 277.016C49.138 276.139 47.818 275.621 46.907 274.649C44.853 272.459 46.249 269.542 46.514 267C46.742 264.817 45.333 264.249 44.046 262.913C43.199 262.034 42.547 260.975 42.099 259.84C42.443 265.448 44.011 265.918 44.011 265.918C44.011 265.918 42.114 277.279 48.285 278.251C48.285 278.251 48.632 283.682 58.391 282.988C58.391 282.988 69.56 292.999 79.799 279.312C80.957 277.766 81.976 276.043 82.888 274.218C81.325 275.678 79.648 277.012 77.906 278.31Z" fill="#E49557"/>
      <path d="M184.284 276.208C180.847 289.469 177.801 289.29 177.801 289.29C177.801 289.29 175.733 300.621 169.599 299.444C169.599 299.444 167.431 304.435 158.485 300.474C158.485 300.474 144.583 306.105 139.591 289.758C137.952 284.401 137.821 278.083 138.388 272.009C139.529 259.572 143.592 248.175 143.592 248.175L172.188 245.402C173.499 249.458 175.228 253.137 176.978 256.494C181.258 264.71 185.637 270.986 184.284 276.208Z" fill="#BD6D2B"/>
      <path opacity="0.3" d="M181.369 284.402C180.265 285.413 179.355 286.003 177.869 286.12C176.699 286.212 175.852 286.065 175.258 287.18C174.912 287.83 174.814 288.71 174.66 289.429C174.197 291.597 174.051 294.486 172.281 296.086C170.948 297.291 170.152 296.328 169.739 295.121C168.679 297.345 167.036 299.451 164.611 300.163C162.355 300.826 160.164 299.783 159.646 297.572C156.649 298.822 153.413 299.637 150.21 298.647C147.15 297.701 144.606 295.292 142.774 292.736C140.495 289.557 139.096 285.864 138.234 282.04C138.434 284.733 138.854 287.345 139.592 289.758C144.584 306.105 158.486 300.475 158.486 300.475C167.432 304.436 169.6 299.445 169.6 299.445C175.734 300.622 177.802 289.291 177.802 289.291C177.802 289.291 179.48 289.384 181.75 284.015C181.621 284.141 181.504 284.278 181.369 284.402Z" fill="#E49557"/>
      <path opacity="0.15" d="M90.1399 238.836C90.1399 238.836 90.0949 250.856 86.9759 262.903C73.8189 256.196 63.1539 246.476 56.1339 235.126C58.8469 232.619 61.6309 229.808 64.1699 226.53L90.1399 238.836Z" fill="#E49557"/>
      <path opacity="0.2" d="M176.979 256.494C166.462 264.753 153.082 270.228 138.389 272.009C139.53 259.572 143.593 248.175 143.593 248.175L172.189 245.402C173.5 249.457 175.229 253.137 176.979 256.494Z" fill="#E49557"/>
      <path d="M152.778 39.143L152.779 39.138C152.71 39.126 152.64 39.116 152.571 39.104C152.502 39.092 152.433 39.078 152.364 39.066L152.363 39.071C104.127 30.848 55.3029 57.926 46.8689 138.794C38.4109 219.895 56.9559 249.489 114.074 259.467C171.192 269.445 198.672 247.891 218.212 168.727C237.697 89.789 200.944 47.761 152.778 39.143Z" fill="#DB8948"/>
      <path d="M150.752 50.339L150.753 50.335C150.706 50.327 150.66 50.321 150.613 50.313C150.567 50.305 150.521 50.295 150.474 50.287L150.473 50.291C118.111 44.811 84.2519 69.32 76.0319 138.238C67.7879 207.354 79.4069 231.902 117.721 238.595C156.034 245.288 175.288 226.134 190.966 158.316C206.598 90.692 183.054 56.156 150.752 50.339Z" fill="#F7EFE9"/>
      <path opacity="0.3" d="M44.98 167.297C44.877 172.334 44.927 177.117 45.136 181.654C47.843 181.768 50.379 181.261 53.068 182.336C57.633 184.16 60.113 188.416 61.265 192.972C62.267 196.932 62.352 201.028 63.436 204.976C64.796 209.926 66.681 214.761 68.903 219.384C73.365 228.665 79.76 237.243 87.877 243.636C91.93 246.828 96.237 249.231 100.898 251.4C103.853 252.775 106.755 254.291 109.845 255.348C111.293 255.843 112.773 256.22 114.249 256.608C115.769 256.743 117.289 256.891 118.819 256.916C122.085 256.969 125.329 256.527 128.575 256.235C133.695 255.775 138.562 254.975 143.457 253.346C153.261 250.084 162.185 244.182 169.53 236.964C173.189 233.368 176.601 229.458 179.559 225.263C181.918 221.917 183.387 218.092 185.672 214.706C188.301 210.81 192.076 207.648 196.989 207.479C199.883 207.38 202.097 208.717 204.683 209.527C206.418 205.33 208.087 200.846 209.698 196.073L44.98 167.297Z" fill="#E49557"/>
      <path d="M112.991 57.576C134.527 56.272 135.402 79.975 135.402 79.975C135.402 79.975 114.605 136.867 72.889 125.642C31.173 114.417 92.177 65.347 92.177 65.347C92.177 65.347 98.986 58.424 112.991 57.576Z" fill="#A15E27"/>
      <path d="M194.967 77.653C176.47 66.545 164.742 87.162 164.742 87.162C164.742 87.162 156.896 147.226 199.078 156.548C241.26 165.87 209.835 94.164 209.835 94.164C209.835 94.164 206.995 84.876 194.967 77.653Z" fill="#A15E27"/>
      <path d="M181.587 205.717C168.417 214.359 167.635 265.169 109.108 250.836C87.045 245.433 76.538 235.607 71.598 224.738C63.428 206.756 70.468 185.899 65.688 177.333C63.718 173.801 62.161 169.386 61.024 164.681C57.725 151.022 57.98 134.933 62.047 131.145C67.525 126.048 71.447 99.41 81.742 86.549C92.045 73.69 127.148 72.298 151.379 78.232C175.61 84.166 206.105 101.624 209.292 117.786C212.479 133.948 203.656 159.386 206.151 166.436C208.645 173.486 194.75 197.065 181.587 205.717Z" fill="#E49557"/>
      <path opacity="0.3" d="M65.688 177.333C65.823 177.575 65.945 177.829 66.062 178.089C67.152 177.23 68.179 176.297 69.093 175.249C73.205 170.534 74.962 164.399 76.384 158.438C77.83 152.38 79.172 146.303 80.442 140.206C82.973 128.053 85.54 115.699 91.036 104.475C95.947 94.445 103.112 85.602 113.409 80.853C120.209 77.717 127.598 76.326 135.027 75.508C113.514 73.391 89.885 76.387 81.742 86.549C71.447 99.41 67.526 126.047 62.047 131.145C57.98 134.933 57.725 151.022 61.024 164.681C62.161 169.386 63.718 173.801 65.688 177.333Z" fill="#E49557"/>
      <path opacity="0.3" d="M181.578 205.715C181.347 205.867 181.121 206.036 180.897 206.213C180.327 204.948 179.848 203.646 179.521 202.294C178.053 196.212 179.33 189.96 180.823 184.017C182.34 177.977 183.958 171.967 185.649 165.973C189.021 154.026 192.453 141.884 192.765 129.39C193.044 118.226 190.777 107.073 183.839 98.103C179.258 92.179 173.347 87.531 167.137 83.373C187.194 91.436 206.765 105.012 209.29 117.787C212.477 133.949 203.646 159.385 206.149 166.437C208.005 171.676 200.797 186.062 191.559 196.651C188.378 200.297 184.957 203.493 181.578 205.715Z" fill="#E49557"/>
      <path opacity="0.3" d="M160.173 209.353C167.216 211.934 174.476 209.108 181.151 206.029C181.295 205.922 181.44 205.814 181.587 205.717C193.728 197.737 206.479 177.072 206.432 168.375C201.544 177.816 193.769 185.864 186.174 193.057C178.96 199.889 170.372 207.74 160.173 209.353Z" fill="#E49557"/>
      <path opacity="0.3" d="M146.751 232.859C153.121 234.419 159.505 234.203 165.39 232.523C156.516 246.483 141.851 258.855 109.107 250.836C87.0439 245.433 76.5369 235.607 71.5969 224.738C69.2019 219.474 68.1199 213.96 67.6639 208.59C72.1059 212.799 77.6689 215.941 84.0299 217.499C97.6559 220.836 111.352 216.034 120.037 206.205C123.198 218.935 133.134 229.524 146.751 232.859Z" fill="#E49557"/>
      <path opacity="0.3" d="M114.296 251.961C114.532 252.006 114.768 252.049 115.002 252.092C115.673 252.214 116.335 252.326 116.989 252.43C117.13 252.452 117.273 252.477 117.413 252.498C120.358 252.949 123.14 253.221 125.762 253.321C125.754 253.315 125.746 253.308 125.738 253.301C126.919 253.346 128.079 253.367 129.198 253.346C122.205 249.783 116.682 244.406 113.135 237.849C112.962 237.468 112.787 237.088 112.624 236.698C112.603 236.713 112.581 236.728 112.56 236.743C112.548 236.72 112.535 236.697 112.524 236.673C112.199 236.944 111.869 237.2 111.539 237.458C105.364 241.635 97.9809 243.851 90.1329 243.779C91.1159 244.315 92.1539 244.831 93.2219 245.338C93.2119 245.34 93.2019 245.342 93.1909 245.344C95.5619 246.467 98.155 247.512 100.976 248.472C101.11 248.518 101.248 248.562 101.384 248.608C102.012 248.819 102.651 249.025 103.302 249.226C103.529 249.297 103.758 249.367 103.988 249.436C104.588 249.617 105.199 249.793 105.819 249.967C106.055 250.033 106.288 250.101 106.527 250.166C107.371 250.395 108.228 250.62 109.108 250.835H109.109C109.989 251.051 110.854 251.247 111.708 251.434C111.95 251.487 112.188 251.534 112.428 251.585C113.057 251.719 113.68 251.844 114.296 251.961Z" fill="#E49557"/>
      <path d="M145.888 100.015C106.589 90.754 136.246 157.169 98.0079 213.726L117.503 215.915L135.804 222.982C128.029 155.156 185.019 109.962 145.888 100.015Z" fill="#D68747"/>
      <path d="M99.5549 178.866C73.3459 176.955 71.597 224.738 71.597 224.738C63.427 206.756 70.467 185.899 65.687 177.333C63.717 173.801 62.1599 169.386 61.0229 164.681C75.4629 155.93 79.062 115.638 108.574 122.865C122.753 126.338 121.338 138.689 119.308 151.568C117.113 165.504 113.18 179.861 99.5549 178.866Z" fill="#F7EFE9"/>
      <path d="M113.143 234.91C113.1 235.948 112.658 236.929 112.092 237.833C111.501 238.73 110.731 239.537 109.841 240.249C108.958 240.956 108.075 241.766 106.776 242.068C106.134 242.221 105.457 242.324 104.731 242.298C104.024 242.281 103.222 242.142 102.59 241.756L102.616 241.552C103.236 241.331 103.675 241.101 104.147 240.864C104.373 240.746 104.602 240.61 104.828 240.505C105.052 240.38 105.277 240.259 105.505 240.15C106.378 239.653 107.493 239.413 108.432 238.911C109.359 238.411 110.235 237.836 111.01 237.173C111.801 236.518 112.45 235.747 112.889 234.849L113.143 234.91Z" fill="#211B18"/>
      <path d="M113.143 234.91C113.118 235.909 113.337 236.893 113.736 237.839C114.116 238.785 114.628 239.7 115.219 240.572C115.82 241.451 116.698 242.18 117.243 243.024C117.395 243.226 117.539 243.437 117.679 243.652C117.831 243.85 117.971 244.076 118.117 244.285C118.427 244.713 118.71 245.121 119.157 245.603L119.085 245.796C118.346 245.847 117.571 245.599 116.936 245.287C116.28 244.975 115.728 244.571 115.229 244.139C114.216 243.27 113.808 242.144 113.352 241.109C112.891 240.066 112.582 238.994 112.472 237.926C112.388 236.863 112.45 235.789 112.891 234.848L113.143 234.91Z" fill="#211B18"/>
      <path d="M120.201 205.544C106.539 202.198 94.1639 209.055 98.0759 217.771C98.0759 217.771 96.8969 232.999 112.539 236.829C128.181 240.66 134.172 226.611 134.172 226.611C141.669 220.689 133.863 208.89 120.201 205.544Z" fill="#1A1A1A"/>
      <path opacity="0.3" d="M133.047 212.571C133.643 214.022 133.859 215.593 133.47 217.194C132.345 221.822 127.754 222.666 123.719 221.931C118.293 220.943 112.523 219.762 107.329 217.884C103.293 216.425 100.494 212.259 103.323 208.224C104.035 207.208 104.949 206.37 105.974 205.705C99.4739 207.697 95.6539 212.376 98.0749 217.771C98.0749 217.771 96.8959 232.999 112.538 236.829C128.18 240.66 134.171 226.611 134.171 226.611C138.712 223.025 137.633 217.285 133.047 212.571Z" fill="#534741"/>
      <path opacity="0.3" d="M125.713 227.239C122.924 227.742 121.658 229.967 119.205 231.001C117.15 231.867 114.672 231.328 112.72 230.437C110.744 229.535 109.454 228.027 108.197 226.32C106.25 223.676 104.444 221.935 101.597 220.288C100.103 219.425 98.793 218.4 97.861 217.204C97.931 217.392 97.991 217.58 98.076 217.77C98.076 217.77 96.897 232.998 112.539 236.828C128.181 240.659 134.172 226.61 134.172 226.61C134.255 226.544 134.323 226.472 134.403 226.405C131.757 227.287 128.385 226.757 125.713 227.239Z" fill="#534741"/>
      <path d="M101.2 218.991C108.434 219.058 109.685 223.2 108.382 225.439C107.078 227.677 101.2 218.991 101.2 218.991Z" fill="black"/>
      <path d="M130.838 226.249C124.392 222.965 121.368 226.061 121.489 228.648C121.611 231.236 130.838 226.249 130.838 226.249Z" fill="black"/>
      <path d="M187.49 171.775C184.657 183.342 172.988 190.42 161.421 187.587C149.845 184.752 142.768 173.083 145.601 161.516C146.078 159.567 146.813 157.735 147.749 156.068C152.368 147.836 162.054 143.34 171.681 145.697C181.316 148.057 187.833 156.539 188.102 165.995C188.163 167.898 187.966 169.835 187.49 171.775Z" fill="#603813"/>
      <path d="M187.71 167.993C184.968 179.189 173.667 186.044 162.471 183.302C151.267 180.558 144.42 169.259 147.162 158.063C147.329 157.38 147.526 156.721 147.749 156.069C152.368 147.837 162.054 143.341 171.681 145.698C181.316 148.058 187.833 156.54 188.102 165.996C188.001 166.66 187.874 167.326 187.71 167.993Z" fill="#1A1A1A"/>
      <path d="M182.042 169.783C177.972 173.064 171.353 171.607 167.258 166.528C163.163 161.449 163.142 154.672 167.211 151.39C171.281 148.109 177.9 149.566 181.995 154.645C186.091 159.724 186.112 166.501 182.042 169.783Z" fill="#F2F2F2"/>
      <path d="M157.923 165.511C155.471 167.488 151.88 167.103 149.903 164.651C147.926 162.199 148.311 158.608 150.763 156.631C153.215 154.654 156.806 155.039 158.783 157.491C160.76 159.943 160.375 163.534 157.923 165.511Z" fill="#F2F2F2"/>
      <path opacity="0.3" d="M178.929 177.771C176.407 177.143 173.82 179.812 171.407 180.174C169.456 180.467 168.129 179.725 167.1 178.118C166.327 176.909 165.762 175.698 164.435 174.973C163.114 174.251 161.635 174.15 160.16 174.286C157.855 174.499 155.605 175.271 153.273 175.168C151.257 175.079 149.411 174.252 148.19 172.609C147.519 171.706 147.111 170.656 146.548 169.691C146.099 168.921 145.601 168.491 145.044 168.306C145.741 177.346 152.13 185.317 161.416 187.591C169.27 189.515 177.17 186.864 182.322 181.347C180.809 180.643 180.771 178.229 178.929 177.771Z" fill="#534741"/>
      <path d="M160.93 181.939C158.478 183.916 154.49 183.038 152.022 179.978C149.554 176.918 149.542 172.834 151.994 170.857C154.446 168.88 158.434 169.758 160.902 172.818C163.369 175.878 163.382 179.962 160.93 181.939Z" fill="#F2F2F2"/>
      <path d="M173.119 185.228C171.351 186.654 168.761 186.376 167.335 184.608C165.909 182.84 166.187 180.25 167.955 178.824C169.723 177.398 172.313 177.676 173.739 179.444C175.165 181.213 174.888 183.802 173.119 185.228Z" fill="#F2F2F2"/>
      <path d="M118.016 154.761C115.183 166.328 103.514 173.406 91.947 170.573C80.371 167.738 73.294 156.069 76.126 144.502C76.603 142.553 77.338 140.721 78.274 139.054C82.892 130.822 92.579 126.326 102.206 128.683C111.841 131.043 118.358 139.525 118.627 148.981C118.688 150.883 118.491 152.82 118.016 154.761Z" fill="#603813"/>
      <path d="M118.236 150.978C115.494 162.174 104.193 169.029 92.9971 166.287C81.7931 163.543 74.9461 152.244 77.6881 141.048C77.8551 140.365 78.0521 139.706 78.2751 139.054C82.8931 130.822 92.5801 126.326 102.207 128.683C111.842 131.043 118.359 139.525 118.628 148.981C118.527 149.645 118.399 150.312 118.236 150.978Z" fill="#1A1A1A"/>
      <path d="M112.568 152.768C108.498 156.049 101.879 154.592 97.784 149.513C93.689 144.434 93.668 137.657 97.737 134.375C101.807 131.094 108.426 132.551 112.521 137.63C116.617 142.709 116.637 149.486 112.568 152.768Z" fill="#F2F2F2"/>
      <path d="M88.449 148.497C85.997 150.474 82.406 150.089 80.429 147.637C78.452 145.185 78.837 141.594 81.289 139.617C83.741 137.64 87.332 138.025 89.309 140.477C91.286 142.929 90.901 146.52 88.449 148.497Z" fill="#F2F2F2"/>
      <path opacity="0.3" d="M112.373 161.951C111.174 160.534 110.171 159.345 108.108 159.603C106.886 159.756 105.777 160.379 104.644 160.815C103.053 161.428 101.489 161.691 100.126 160.461C99.1371 159.569 98.726 158.26 97.925 157.223C96.207 155.001 93.6961 155.819 91.5881 156.919C89.6881 157.91 87.8081 159.201 85.5711 159.114C83.3901 159.029 81.8561 157.8 80.9541 155.884C80.3451 154.591 79.9131 152.697 78.2571 152.311C77.4241 152.117 76.5631 152.388 75.7421 152.813C77.0001 161.212 83.183 168.428 91.942 170.573C100.301 172.62 108.714 169.489 113.808 163.231C113.323 162.927 112.844 162.507 112.373 161.951Z" fill="#534741"/>
      <path d="M91.455 164.924C89.003 166.901 85.015 166.023 82.547 162.963C80.079 159.903 80.067 155.819 82.519 153.842C84.971 151.865 88.959 152.743 91.427 155.803C93.895 158.863 93.908 162.947 91.455 164.924Z" fill="#F2F2F2"/>
      <path d="M103.645 168.214C101.877 169.64 99.2871 169.362 97.8611 167.594C96.4351 165.826 96.713 163.236 98.481 161.81C100.249 160.384 102.839 160.662 104.265 162.43C105.691 164.198 105.413 166.788 103.645 168.214Z" fill="#F2F2F2"/>
      <path opacity="0.3" d="M167.245 126.231C164.166 126.38 162.099 128.718 161.453 131.887C160.812 135.031 161.789 137.915 164.022 140.172C167.524 143.713 172.754 145.567 177.421 146.994C179.951 147.767 182.598 148.507 185.265 148.463C187.748 148.423 191.557 146.898 191.261 143.958C181.133 144.658 169.52 136.309 167.245 126.231Z" fill="#D68747"/>
      <path d="M192.39 143.839C189.02 147.36 180.38 145.081 173.411 138.413C166.443 131.745 163.785 123.213 167.155 119.691C170.525 116.169 175.79 121.976 182.758 128.644C189.726 135.312 195.76 140.317 192.39 143.839Z" fill="#BD6D2B"/>
      <path opacity="0.3" d="M115.13 113.467C117.791 115.022 118.545 118.05 117.653 121.159C116.769 124.243 114.57 126.349 111.547 127.32C106.805 128.842 101.31 128.069 96.5119 127.178C93.9109 126.695 91.222 126.127 88.877 124.856C86.694 123.673 84.0199 120.56 85.6409 118.09C94.2989 123.391 108.455 121.354 115.13 113.467Z" fill="#D68747"/>
      <path d="M84.695 117.464C86.056 122.144 94.772 124.116 104.033 121.422C113.294 118.728 119.593 112.39 118.232 107.71C116.871 103.03 109.518 105.746 100.257 108.44C90.996 111.133 83.333 112.784 84.695 117.464Z" fill="#BD6D2B"/>
      <path opacity="0.3" d="M82.992 190.451C75.554 189.486 70.42 183.624 65.924 177.809C65.846 177.647 65.768 177.485 65.682 177.331C58.603 164.644 56.844 140.425 60.904 132.734C60.876 143.366 64.053 154.095 67.464 163.983C70.704 173.377 74.692 184.307 82.992 190.451Z" fill="#B3B3B3"/>
      <path opacity="0.3" d="M116.888 207.831C116.444 206.479 108.771 205.796 106.928 207.116C105.71 207.988 104.739 209.699 105.954 210.999C107.078 212.2 109.345 211.961 110.813 211.772C112.645 211.537 117.875 210.835 116.888 207.831Z" fill="#F2F2F2"/>
      <path d="M194.967 77.653C226.576 98.211 208.505 100.267 232.346 115.233C256.187 130.198 269.959 142.653 264.335 154.151C258.711 165.649 232.834 216.275 205.143 210.408C177.452 204.541 192.917 162.363 198.631 143.865C204.346 125.366 213.165 95.809 194.967 77.653Z" fill="#BD6D2B"/>
      <path opacity="0.3" d="M253.885 130.62C256.146 136.531 256.166 142.706 254.658 148.816C252.644 156.979 250.035 164.496 246.095 171.939C243.273 177.27 240.103 182.503 235.779 186.763C231.247 191.227 225.702 194.317 219.842 196.691C219.367 196.883 219.084 196.202 219.457 195.921C224.454 192.157 226.613 185.437 227.721 179.222C224 186.85 216.307 193.574 208.54 196.038C200.199 198.684 193.62 194.574 189.176 188.358C189.526 199.136 193.553 207.953 205.142 210.408C232.832 216.275 258.71 165.649 264.334 154.151C267.916 146.829 263.624 139.118 253.885 130.62Z" fill="#E49557"/>
      <path d="M112.991 57.576C75.459 61.201 90.535 71.375 62.477 73.63C34.419 75.885 16.45 80.566 16.124 93.362C15.798 106.158 15.354 163.011 42.621 170.604C69.889 178.197 75.665 133.646 79.145 114.601C82.626 95.556 88.463 65.269 112.991 57.576Z" fill="#BD6D2B"/>
      <path opacity="0.3" d="M60.411 156.648C56.938 157.755 53.88 157.53 51.124 156.482C48.807 156.204 46.539 155.575 44.403 154.635C44.144 154.559 43.887 154.492 43.626 154.406C25.877 148.615 21.764 128.385 21.456 111.875C21.371 107.322 21.638 102.268 22.513 97.325C22.976 92.878 23.824 88.479 25.621 84.356C26.308 82.779 27.134 81.298 28.05 79.884C20.545 82.918 16.281 87.196 16.124 93.362C15.798 106.158 15.354 163.011 42.621 170.604C56.913 174.584 65.298 164.237 70.546 150.748C67.519 153.276 64.139 155.46 60.411 156.648Z" fill="#E49557"/>
    </svg>
    <div>
      <h2>Conéctate a internet</h2>
      <p>No estás conectado. Revisa tu conexión.</p>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "OfflineComponent",
}
</script>
  
<style lang="scss" scoped>
.container {
  height: 100vh;
  height: 100dvh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  background-color: var(--white-color);
  text-align: center;
  flex-direction: column;
  overflow: hidden;
  gap: 2rem;

  svg {
    width: 100px;
  }
}
</style>
  