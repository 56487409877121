var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn",class:{
    'btn--loading': _vm.estaCargando,
    'btn--green': _vm.green,
    'btn--orange': _vm.orange,
    'btn--red': _vm.red,
    'btn--pink': _vm.pink,
    'btn--pink-inverted': _vm.pinkInverted,
    'btn--inverted': _vm.inverted,
    'btn--full': _vm.fullWidth,
  },attrs:{"type":_vm.submit ? 'submit' : 'button',"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_c('span',[_vm._v(_vm._s(_vm.text))])])
}
var staticRenderFns = []

export { render, staticRenderFns }