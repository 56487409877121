<template>
  <div id="app" :class="{ 'app--sidebar': useUserNavigation }" v-if="!offline">
    <Navbar
      :sidebar-mode="useUserNavigation"
      :user-mode="useUserNavigation && width < 1024"
      :has-admin-role="$store.state.user.user?.privilegio === 'admin'"
      :is-admin="$route.path.includes('master')" />
    <transition
      name="routes-transition"
      mode="out-in"
    >
      <router-view />
    </transition>
    <PublicFooter />
  </div>
  <offline v-else />
</template>

<script>
import PublicFooter from "@/components/universal/PublicFooter.vue";
import Navbar from "./components/universal/Navbar.vue";
import Offline from "./components/Offline.vue";
import { mapState } from "vuex";

export default {
  name: "App",
  components: {
    PublicFooter,
    Navbar,
    Offline
  },
  data: () => ({
    offline: false,
    width: window.innerWidth
  }),
  computed: {
    ...mapState("user", ["user"]),
    useUserNavigation() {
      return this.$store.getters["user/isLoggedIn"];
    },
  },
  async created() {
    const needUserCompletion =
      this.user?.estado_registro == "parcial" &&
      this.$route.name !== "profile-completion";
    if (needUserCompletion) {
      await this.$router.replace("/sign-up/profile-completion");
    }

    window.addEventListener("offline", () => {
      this.offline = true;
    });
    window.addEventListener("online", () => {
      this.offline = false;
    });
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
    }
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }
};
</script>

<style lang="scss">
#app {
  &:not(:has(.navbar--sidebar-mode)):has(.animal-profile),
  &:has(.home) {
    .footer {
      display: flex;
    }
  }
}

.routes-transition-enter-active,
.routes-transition-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.routes-transition-enter {
  opacity: 0;
  transform: translate(2em, 0);
}

.routes-transition-leave-active {
  opacity: 0;
  transform: translate(-2em, 0);
}

@media screen and (min-width: 1024px) {
  .app--sidebar:not(:has(.profile-completion)) {
    display: grid;
    grid-template-columns: 4rem 1fr;
    overflow: hidden;

    & > div {
      height: 100vh !important;
      height: 100dvh !important;
      grid-column: 2/3;
      width: 100%;
    }

    .footer {
      display: flex;
      grid-column: 2/3;
    }
  }
}
@media screen and (max-height: 650px) {
  .app--sidebar:not(:has(.profile-completion)) {
    grid-template-columns: 3.2rem 1fr;
  }
}
</style>
