<template>
  <nav
    class="navbar"
    :class="{
      'navbar--user-mode': userMode && !$route.path.includes('master'),
      'navbar--sidebar-mode': sidebarMode,
      'navbar--opened': menuOpened,
      'navbar--admin': isAdmin,
      'navbar--scrolled': scrollPosition > 50 && !isLoggedIn,
    }"
  >
    <div class="navbar__container">
      <router-link class="logo" :to="{ name: 'home' }">
        <img
          src="@/assets/img/logos/rescue-me-logo-no-bg.svg?inline"
          alt=""
          v-if="isAdmin"
        />
        <img src="@/assets/img/logos/rescue-me-logo.svg?inline" alt="" v-else />
        <span>RescueMe</span>
      </router-link>
      <button type="button" @click="toggleMenu" class="navbar__menu">
        <span class="navbar__menu__line"></span>
        <span class="navbar__menu__line"></span>
        <span class="navbar__menu__line"></span>
      </button>

      <!-- User signed in -->
      <ul class="navbar__links" v-if="isLoggedIn && !isAdmin">
        <li class="navbar__menu-text">Menu</li>
        <li
          @click="closeMenu"
          class="navbar__links__item"
          :class="{ 'navbar__links__item--qr': $route.name === 'pets' }"
        >
          <router-link :to="{ name: 'pets' }">
            <base-icon class="item-icon" icon="user-home" v-if="!userMode" />
            <div class="pet-bg" v-else>
              <base-icon class="item-icon" icon="my-pets" />
            </div>
            <span>Animales</span>
          </router-link>
          <router-link :to="{ name: 'scan-qr' }">
            <base-icon class="item-icon" icon="qr" />
            <span>Escanear QR</span>
          </router-link>
        </li>
        <li @click="closeMenu" class="navbar__links__item">
          <router-link :to="{ name: 'adoptions' }">
            <base-icon class="item-icon" icon="phone" />
            <span>Adopciones</span>
          </router-link>
        </li>
        <li @click="closeMenu" class="navbar__links__item">
          <router-link :to="{ name: 'feed' }">
            <base-icon class="item-icon" icon="feed" />
            <span>Novedades</span>
          </router-link>
        </li>
        <li @click="closeMenu" class="navbar__links__item">
          <router-link :to="{ name: 'store' }">
            <base-icon class="item-icon" icon="store" />
            <span>Productos</span>
          </router-link>
        </li>
        <li class="navbar__divisor"></li>
        <li @click="closeMenu" class="navbar__links__item">
          <router-link :to="{ name: 'settings' }">
            <base-icon class="item-icon" icon="user" />
            <span>Cuenta</span>
          </router-link>
        </li>
        <li
          @click="closeMenu"
          class="navbar__links__item"
          v-if="hasAdminRole && !userMode"
        >
          <router-link :to="{ name: 'admin-dashboard' }">
            <base-icon class="item-icon" icon="admin" />
            <span>Administrador</span>
          </router-link>
        </li>
        <li @click="closeMenu" class="navbar__links__item">
          <button @click="logout">
            <base-icon class="item-icon logout" icon="logout" />
            <span>Cerrar sesión</span>
          </button>
        </li>
      </ul>

      <!-- Anonymous user -->
      <ul class="navbar__links" v-else-if="!isLoggedIn && !isAdmin">
        <li @click="closeMenu" class="navbar__links__item">
          <router-link :to="{ name: 'login' }">
            <base-icon class="item-icon" icon="login" />
            <span>Iniciar sesión</span>
          </router-link>
        </li>
        <li @click="closeMenu" class="navbar__links__item sign-up">
          <router-link :to="{ name: 'sign-up' }">
            <base-icon class="item-icon" icon="user" />
            <span>Registrarme</span>
          </router-link>
        </li>
        <li @click="closeMenu" class="navbar__links__item sign-up" id="cta-btn">
          <router-link :to="{ name: 'scan-qr' }">
            <base-icon class="item-icon" icon="qr-2" />
            <span>Escanear QR</span>
          </router-link>
        </li>
        <li @click="closeMenu" class="navbar__links__item" v-if="userMode">
          <router-link :to="{ name: 'privacy' }">
            <base-icon class="item-icon" icon="privacy" />
            <span>Política de Privacidad</span>
          </router-link>
        </li>
        <li @click="closeMenu" class="navbar__links__item" v-if="userMode">
          <router-link :to="{ name: 'terms-conditions' }">
            <base-icon class="item-icon" icon="terms" />
            <span>Términos y Condiciones</span>
          </router-link>
        </li>
        <li @click="closeMenu" class="navbar__links__item" v-if="userMode">
          <router-link :to="{ name: 'faq' }">
            <base-icon class="item-icon" icon="faq" />
            <span>Preguntas Frecuentes</span>
          </router-link>
        </li>
      </ul>

      <!-- Admin user -->
      <ul class="navbar__links" v-else>
        <li class="navbar__menu-text">Menu</li>
        <li @click="closeMenu" class="navbar__links__item">
          <router-link :to="{ name: 'admin-dashboard' }">
            <base-icon class="item-icon" icon="dashboard" />
            <span>Dashboard</span>
          </router-link>
        </li>
        <li @click="closeMenu" class="navbar__links__item">
          <router-link :to="{ name: 'admin-users' }">
            <base-icon class="item-icon" icon="user" />
            <span>Usuarios</span>
          </router-link>
        </li>
        <li @click="closeMenu" class="navbar__links__item">
          <router-link :to="{ name: 'admin-animals' }">
            <base-icon class="item-icon" icon="my-pets" />
            <span>Animales</span>
          </router-link>
        </li>
        <li @click="closeMenu" class="navbar__links__item">
          <router-link :to="{ name: 'admin-feed' }">
            <base-icon class="item-icon" icon="feed" />
            <span>Novedades</span>
          </router-link>
        </li>
        <li @click="closeMenu" class="navbar__links__item">
          <router-link :to="{ name: 'admin-qr' }">
            <base-icon class="item-icon" icon="qr-2" /><span>Códigos QR</span>
          </router-link>
        </li>
        <li @click="closeMenu" class="navbar__links__item">
          <router-link :to="{ name: 'admin-store' }">
            <base-icon class="item-icon" icon="store" />
            <span>Productos</span>
          </router-link>
        </li>
        <li @click="closeMenu" class="navbar__links__item">
          <router-link :to="{ name: 'admin-orders' }">
            <base-icon class="item-icon" icon="orders" />
            <span>Pédidos</span>
          </router-link>
        </li>
        <li class="navbar__divisor"></li>
        <li @click="closeMenu" class="navbar__links__item">
          <router-link :to="{ name: 'pets' }">
            <base-icon class="item-icon" icon="home" />
            <span>Ir a inicio</span>
          </router-link>
        </li>
        <li @click="closeMenu" class="navbar__links__item">
          <button @click="logout">
            <base-icon class="item-icon" icon="logout" />
            <span>Cerrar sesión</span>
          </button>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NavigationComponent",
  props: {
    sidebarMode: {
      type: Boolean,
      default: false,
    },
    userMode: {
      type: Boolean,
      default: false,
    },
    hideMobile: {
      type: Boolean,
      default: false,
    },
    hasAdminRole: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    menuOpened: false,
    scrollPosition: 0,
  }),
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  computed: {
    ...mapGetters("user", ["isLoggedIn"]),
  },
  methods: {
    handleScroll() {
      this.scrollPosition = window.scrollY;
    },
    toggleMenu() {
      this.menuOpened = !this.menuOpened;
      this.scrollPosition = 51;
    },
    closeMenu() {
      this.menuOpened = false;
    },
    async logout() {
      try {
        await this.$store.dispatch("user/doLogout");
        await this.$router.push({ name: "home" });

        this.$toast.success("Hasta la proxima!");
      } catch (error) {
        // Manejar el error en caso de que ocurra durante el cierre de sesión
        console.log(error);
      }
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  background-color: var(--white-color);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  transition: 300ms ease;

  &__container {
    height: var(--nav-height);
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-inline: 1.5rem;
  }

  &__divisor,
  &__menu-text {
    display: none;
    opacity: 0;
    user-select: none;
  }

  .logo {
    align-items: center;
    display: flex;
    gap: 0.75em;
    font-size: 1em;

    img {
      width: 2em;
      height: 2em;
    }

    span {
      color: var(--first-color);
      font-size: 1.125rem;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  &__menu {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    height: 1.5rem;
    justify-content: center;
    line-height: 0;
    padding: 0;
    position: relative;
    width: 1.875rem;

    &__line {
      background-color: var(--black-color);
      display: block;
      height: 0.25rem;
      position: absolute;
      width: 100%;
      transition: background 300ms, transform 300ms, top 300ms 300ms,
        bottom 300ms 300ms;

      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(3) {
        bottom: 0;
      }
    }
  }

  &__links {
    background-color: var(--white-color);
    display: flex;
    flex-direction: column;
    height: 0;
    left: 0;
    margin: 0;
    overflow: hidden;
    padding: 0 1.5rem;
    position: fixed;
    transition: height 300ms;
    top: 3.75rem;
    width: 100%;
    z-index: 10;

    &__item {
      a,
      button {
        user-select: none;
        box-sizing: content-box;
        align-items: center;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #ccc;
        color: #000;
        display: flex;
        font-size: 1.25rem;
        gap: 1rem;
        height: 4.375rem;
        line-height: 0.9rem;
        outline: none;
        padding: 0;
        padding-top: 2rem;
        text-transform: initial;
        transition: color 300ms, transform 0ms 450ms;
        transform: translateX(calc(-100% - 1.5rem));
        width: 100%;

        &.router-link-active,
        &:hover {
          color: var(--first-color);
        }

        &:nth-child(2) {
          display: none;
        }

        .item-icon {
          font-size: 1.2em;
        }
      }
    }
  }
  &--opened {
    .navbar__menu__line {
      background-color: var(--first-color);
      transition: background 300ms 300ms, transform 300ms 300ms, top 300ms,
        bottom 300ms;

      &:nth-child(1) {
        top: 0.625rem;
        transform: rotateZ(45deg);
      }
      &:nth-child(2) {
        transform: scaleX(0);
      }
      &:nth-child(3) {
        transform: rotateZ(-45deg);
        bottom: 0.625rem;
      }
    }

    .navbar__links {
      height: 100%;

      &__item a,
      button {
        transition: color 300ms, transform 300ms 400ms;
        transform: translateX(0);
      }
    }
  }

  /* User navigation style */
  &--user-mode {
    top: initial;
    bottom: 0;

    &:has(+ .user-navigation .product-showcase),
    &:has(+ .user-navigation .cart),
    &:has(+ .thanks) {
      display: none;
    }
    .navbar__container {
      padding-inline: 0;
      width: 100%;
      height: 3.125rem;
    }

    .navbar__menu,
    .logo {
      display: none;
    }
    .navbar__links {
      display: flex;
      flex-direction: row;
      padding: 0;
      position: initial;
      overflow: initial;
      height: 100%;

      &__item {
        flex: 1;

        &--qr {
          & > *:nth-child(1) {
            display: none;
          }
          & > *:nth-child(2) {
            display: flex;
          }
        }

        &:nth-child(2) {
          order: 3;
          position: relative;
          & > * {
            background-color: var(--white-color);
            border-radius: 100%;
            position: absolute;
            width: 5rem !important;
            height: 5rem !important;
            left: 50%;
            transform: translateX(-50%) !important;
            top: -2rem;
            border-bottom: none;

            .item-icon {
              font-size: 2.125rem;
            }
            span {
              display: none;
            }
          }
        }
        &:nth-child(3) {
          order: 2;
        }
        &:nth-child(4) {
          order: 1;
        }
        &:nth-child(5) {
          order: 4;
        }
        &:nth-child(7) {
          order: 5;
        }

        a,
        button {
          align-items: center;
          color: var(--black-color-light);
          flex-direction: column;
          gap: 0;
          height: 100%;
          justify-content: center;
          padding: 0;
          position: relative;
          transform: initial;
          transition: background 300ms, color 300ms;
          width: 100%;

          span {
            font-size: 0.55rem;
          }

          &.router-link-active {
            color: var(--first-color);
          }
        }
        &:last-child {
          display: none;
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  :global(:root) {
    --nav-height: 5.625rem !important;
  }
  :global(.navbar.navbar--sidebar-mode + *) {
    margin-bottom: 0;
  }

  /* Horizontal navbar */
  .navbar {
    background-color: transparent;
    &__container {
      max-width: 1120px;
      margin: 0 auto;
    }
    .logo {
      img {
        width: 3em;
        height: 3em;
      }

      span {
        font-size: 1.5em;
      }
    }
    &__menu {
      display: none;
    }
    &__links {
      flex-direction: row;
      height: 100%;
      padding: initial;
      position: initial;
      top: initial;
      width: initial;
      background-color: transparent;

      &__item a,
      button {
        border: none;
        font-size: 0.9rem;
        height: 100%;
        padding: 0 1rem;
        transform: initial;
        transition: color 300ms;
        box-sizing: border-box;

        .item-icon {
          display: none;
        }
      }

      &__item#cta-btn {
        margin-left: 1rem;
      }

      &__item#cta-btn a span {
        background-color: var(--first-color);
        color: var(--white-color);
        padding: 1rem 2.5rem;
        border-radius: 12px;
        font-weight: 600;
        border: 2px solid var(--first-color);
        transition: 300ms ease;


        &:hover {
          background-color: var(--white-color);
          color: var(--first-color);
        } 
      }

      span {
        font-size: 18px;
      }
    }
  }

  .sign-up a {
    padding-right: 0;
  }

  .navbar.navbar--scrolled {
    background-color: #fff;
  }

  /* Sidebar style */
  .navbar.navbar--sidebar-mode {
    height: 100%;
    transition: width 300ms;
    width: 4em;

    &:has(+ .user-navigation .single-product),
    &:has(+ .user-navigation .cart),
    &:has(+ .thanks) {
      display: initial;
    }
    .navbar__container {
      background-color: var(--white-color);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      flex-direction: column;
      height: 100%;
      margin: 0;
      padding: 0;
      position: relative;
      width: 100%;
    }

    .navbar__menu-text {
      display: block;
      font-weight: 600;
      padding: 1.125em;
      text-transform: uppercase;
      transition: opacity 300ms;
    }

    .navbar__divisor {
      display: block;
      margin-top: auto;
    }

    .logo {
      display: flex;
      margin-top: 0.875em;
      margin-left: 2em;
      margin-bottom: 2em;
      width: 100%;

      img {
        width: 2.25em;
        height: 2.25em;
      }
      span {
        left: 4em;
        overflow: hidden;
        position: absolute;
        transition: width 300ms;
        width: 0;
        font-size: initial;
      }
    }

    &:hover,
    &.navbar--opened {
      width: 16rem;
      .logo span {
        width: 6rem;
      }
      .navbar__menu-text {
        opacity: 1;
      }
    }

    .navbar__links {
      background-color: transparent;
      flex-direction: column;
      height: 100%;
      width: 100%;
      overflow: hidden;

      &__item {
        flex: initial;
        width: 100%;
        order: initial;

        &:last-child {
          display: flex;
        }

        &--qr {
          & > *:nth-child(1) {
            display: flex;
          }
          & > *:nth-child(2) {
            display: none;
          }
        }

        &:nth-child(2) {
          position: initial;
          & > * {
            background-color: transparent;
            box-shadow: initial;
            border-radius: initial;
            position: relative;
            width: 100% !important;
            height: 4em !important;
            left: initial;
            transform: initial !important;
            top: initial;
            .item-icon {
              font-size: 1.5em;
            }
          }
        }

        a,
        button {
          font-size: 1em;
          color: var(--black-color-light);
          flex-direction: row;
          height: 4em;
          justify-content: initial;
          padding: 1.125em;
          position: relative;
          white-space: nowrap;
          width: 100%;
          transition: background 300ms, color 300ms;

          &.router-link-active,
          &:hover {
            color: var(--first-color);
          }

          .item-icon {
            font-size: 1.5em;
            display: block;
          }
          span {
            display: block;
            font-size: 1.2em;
            left: 4em;
            position: absolute;
          }
        }
      }
    }
  }
  .navbar.navbar--sidebar-mode.navbar--admin {
    background-color: var(--second-color);
    color: #fff;
    .logo {
      img {
        width: 2em;
        height: 2em;
      }
      span {
        color: #fff;
      }
    }
    .navbar__container {
      background-color: var(--second-color);
      button,
      a {
        color: #fff;

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }

  .navbar:not(:is(.navbar--sidebar-mode)):has(+ .pet-profile) {
    display: none;
    :global(.pet-profile) {
      margin-top: 0 !important;
    }
  }
}

@media screen and (max-height: 650px) {
  .navbar.navbar--sidebar-mode {
    font-size: 0.8em;
  }
}

.logout {
  color: crimson !important;
}
.pet-bg {
  background-color: var(--second-color);
  border-radius: inherit;
  box-sizing: content-box;
  color: #fff;
  padding: 0.45rem;
}

/* Global styles */
:global(.navbar + *) {
  margin-top: var(--nav-height);
}
:global(.navbar--sidebar-mode + *, .navbar--user-mode + *) {
  margin-top: 0;
}
:global(.navbar--user-mode + *) {
  margin-bottom: var(--nav-height);
}
:global(:root:has(.navbar--user-mode)) {
  --nav-height: 5rem;
}
:global(:root:has(.navbar--user-mode + .user-navigation .thanks))
  :global(:root:has(.navbar--user-mode + .user-navigation .cart)),
:global(:root:has(.navbar--user-mode + .user-navigation .product-showcase)) {
  --nav-height: 0px;
}
</style>
