import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/styles/main.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { onAuthStateChanged } from "firebase/auth";
import { getDoc, doc } from "firebase/firestore";
import { firestoreInstance, authInstance } from "@/firebase/init";
import Loading from "./components/universal/Loading.vue";
import ProgressBar from "./components/universal/ProgressBar.vue";
import { formatDate, calcularEdad } from "./utils";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import AOS from "aos";
import "aos/dist/aos.css";
import './registerServiceWorker'

Vue.prototype.$formatDate = formatDate;
Vue.prototype.$calcularEdad = calcularEdad;

Vue.component("Loading", Loading);
Vue.component("ProgressBar", ProgressBar);

const options = {
  position: "bottom-center",
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  closeButton: "button",
};

Vue.use(Toast, options);

Vue.config.productionTip = false;

const requireComponent = require.context(
  "./components/Base",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
  );

  Vue.component(componentName, componentConfig.default || componentConfig);
});

const cartData = JSON.parse(localStorage.getItem("cart"));
if (cartData) {
  store.commit("cart/SET_CART", cartData);
}

let app;

onAuthStateChanged(authInstance, async (user) => {
  if (!app) {
    if (user) {
      const userData = await getUserData(user.uid);
      const combinedUser = { ...user, ...userData };
      store.commit("user/SET_USER", combinedUser);
    } else {
      store.commit("user/SET_USER", null);
    }

    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
AOS.init({
  easing: "ease",
  once: true,
  delay: 400,
  duration: 500,
  offset: 60,
});

Vue.use(AOS);

async function getUserData(uid) {
  try {
    const userDocRef = doc(firestoreInstance, "usuarios", uid);
    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      return userDocSnapshot.data();
    } else {
      console.log(
        'El usuario no tiene datos adicionales en la colección "usuarios".'
      );
      return {};
    }
  } catch (error) {
    console.error("Error al obtener los datos adicionales del usuario:", error);
    return {};
  }
}
