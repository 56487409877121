<template>
  <footer class="footer">
    <section class="footer__section app-info">
      <div class="logo">
        <img src="@/assets/img/logos/rescue-me-logo.svg?inline" alt="" />
        <h3>RESCUEME</h3>
      </div>
      <p class="footer__section__body phrase">
        Una herramienta imprescindible para proteger a tus queridas mascotas:
        registro eficiente y contacto instantáneo en situaciones de extravío.
      </p>
    </section>
    <section class="footer__section">
      <h3 class="footer__section__title">Redes sociales</h3>
      <div class="footer__section__body">
        <ul class="social-links">
          <li data-aos="fade-right">
            <a
              href="https://www.instagram.com/"
              target="_blank"
              rel="noopener noreferer"
              ><base-icon icon="instagram"
            /></a>
          </li>
          <li data-aos="fade-down">
            <a
              href="https://www.facebook.com/"
              target="_blank"
              rel="noopener noreferer"
              ><base-icon icon="facebook"
            /></a>
          </li>
          <li data-aos="fade-left">
            <a
              href="mailto:contacto@rescueme.cl"
              target="_blank"
              rel="noopener noreferer"
              ><base-icon icon="mail"
            /></a>
          </li>
        </ul>
      </div>
    </section>
    <section class="footer__section">
      <h3 class="footer__section__title">Información</h3>
      <div class="footer__section__body">
        <ul class="app-links">
          <li data-aos="fade-up">
            <router-link :to="{ name: 'terms-conditions' }"
              >Términos y Condiciones</router-link
            >
          </li>
          <li data-aos="fade-up" data-aos-delay="500">
            <router-link :to="{ name: 'privacy' }"
              >Política de Privacidad</router-link
            >
          </li>
          <li data-aos="fade-up" data-aos-delay="600">
            <router-link :to="{ name: 'faq' }"
              >Preguntas Frecuentes</router-link
            >
          </li>
        </ul>
      </div>
    </section>
    <section class="footer__section footer__section--final">
      <p class="footer__section__body copyright">RescueMe © 2023</p>
    </section>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: var(--first-color);
  display: none;
  flex-wrap: wrap;
  padding: 30px;
  gap: 3rem;
  color: #fff;
  overflow: hidden;

  &__section {
    display: flex;
    flex: 1 0 min(300px, 100%);
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    height: 100%;

    &--final {
      flex: 0 0 100%;
    }

    &.app-info {
      display: flex;
      flex-direction: column;
    }

    &__title {
      color: var(--white-color);
    }

    &__body {
      text-align: center;

      &.phrase {
        text-align: center;
        color: var(--white-color);
        line-height: 170%;
        font-weight: 600;
      }

      &.copyright {
        color: var(--white-color);
        text-transform: uppercase;
        font-size: .75rem;

        @media (min-width: 768px) {
          font-size: 1rem;
        }
      }

      .social-links {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3rem;
        list-style-type: none;
        a {
          display: flex;
          border: 1px solid var(--white-color);
          border-radius: 50%;
          width: 40px;
          height: 40px;
          align-items: center;
          justify-content: center;
          color: var(--white-color);
          font-size: 1.5rem;
          transition: color 300ms, background-color 300ms;

          &:hover {
            color: var(--first-color);
            background-color: var(--white-color);
          }
        }
      }

      .app-links {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        a {
          color: var(--white-color);
          border-bottom: 2px solid transparent;
          transition: border 300ms ease-out;

          &:hover {
            border-color: #fff;
          }
        }
      }
    }
  }

  .logo {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    img {
      width: 50px;
      height: 50px;
      border: 2px solid #fff;
      border-radius: 10px;
    }

    h3 {
      color: #fff;
      font-size: 28px;
    }
  }
}
</style>
