<template>
  <component :is="require(`@/assets/svg/icons/${icon}.svg`).default" v-bind="$attrs" @click="$emit('click')"></component>
</template>
<script>
export default {
  name: "BaseIcon",
  props: {
    icon: String,
  },
};
</script>
