import moment from "moment-timezone";
import Vue from "vue";

export const formatDate = (dateString) => {
  if (!dateString) {
    return ""; // Devuelve un valor predeterminado en caso de que dateString sea undefined
  }

  // Parseamos el dateString en un objeto moment para poder formatear la fecha
  const santiagoDate = moment(dateString);

  // Obtenemos los componentes de la fecha: año, mes y día
  const year = santiagoDate.format("YYYY");
  const month = santiagoDate.format("MM");
  const day = santiagoDate.format("DD");

  // Devolvemos la fecha en formato "yyyy-MM-dd"
  return `${day}-${month}-${year}`;
};

export function formatRut(rut) {
  if (!rut) {
    return ""; // Devuelve un valor predeterminado en caso de que rut sea undefined
  }

  const cleanRut = rut.replace(/[^0-9kK]/g, "").toUpperCase();
  const rutDigits = cleanRut.slice(0, -1);
  const rutVerifier = cleanRut.slice(-1);

  let formattedRut = "";

  for (let i = rutDigits.length - 1, j = 0; i >= 0; i--, j++) {
    formattedRut = rutDigits.charAt(i) + formattedRut;
    if (j % 3 === 2 && i !== 0) {
      formattedRut = "." + formattedRut;
    }
  }

  formattedRut += `-${rutVerifier}`;

  return formattedRut;
}

export function calcularEdad(fecha) {
  if (!fecha) {
    return ""; // Devuelve un valor predeterminado en caso de que la fecha sea undefined
  }

  const [dia, mes, año] = fecha.split("/").map(Number); // Convertir la fecha del input a números separados

  const fechaNacimiento = new Date(año, mes - 1, dia); // Crear el objeto Date a partir de los números
  const fechaActual = new Date();

  const años = fechaActual.getFullYear() - fechaNacimiento.getFullYear();
  const meses = fechaActual.getMonth() - fechaNacimiento.getMonth();
  const días = fechaActual.getDate() - fechaNacimiento.getDate();

  let resultado = "";

  if (años > 0) {
    resultado += `${años} año${años > 1 ? "s" : ""}`;
  } else if (meses > 0) {
    resultado += `${meses} mes${meses > 1 ? "es" : ""}`;
  } else {
    resultado += `${días} día${días !== 1 ? "s" : ""}`;
  }

  return resultado;
}



Vue.filter("timeAgo", function (timestamp) {
  // Verificar si se proporcionó un timestamp válido
  if (!timestamp || typeof timestamp.toDate !== "function") return "";

  // Obtener la hora actual del servidor utilizando moment.js y serverTimestamp() de Firestore
  const currentServerTime = moment(); // Obtenemos la hora actual del servidor usando Moment.js

  // Calcular la diferencia en minutos entre la fecha del timestamp y la fecha actual del servidor
  const diffInMinutes = currentServerTime.diff(
    moment(timestamp.toDate()),
    "minutes"
  );

  // Definir las reglas de formato para diferentes intervalos de tiempo
  if (diffInMinutes < 1) {
    return "justo ahora";
  } else if (diffInMinutes < 60) {
    return `hace ${diffInMinutes} ${
      diffInMinutes === 1 ? "minuto" : "minutos"
    }`;
  } else if (diffInMinutes < 1440) {
    const hours = Math.floor(diffInMinutes / 60);
    return `hace ${hours} ${hours === 1 ? "hora" : "horas"}`;
  } else if (diffInMinutes < 43200) {
    const days = Math.floor(diffInMinutes / 1440);
    return `hace ${days} ${days === 1 ? "día" : "días"}`;
  } else if (diffInMinutes < 43200 * 30) {
    return "Hace más de un mes";
  } else {
    return moment(timestamp.toDate()).format("DD/MM/YYYY");
  }
});

Vue.filter("currencyToCLP", function (value) {
  if (typeof value !== "number") {
    return value;
  }

  return `$ ${value.toLocaleString("es-CL")}`;
});

Vue.filter("discountAmount", function (price, offerPrice) {
  if (typeof price !== "number" || typeof offerPrice !== "number") {
    return "";
  }

  const discount = (1 - offerPrice / price) * 100; 
  const formattedDiscount = discount > 0 ? `${Math.round(discount)}%` : "0%";
  return formattedDiscount;
});


