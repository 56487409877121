<template>
  <button
    class="btn"
    @click="$emit('click', $event)"
    :class="{
      'btn--loading': estaCargando,
      'btn--green': green,
      'btn--orange': orange,
      'btn--red': red,
      'btn--pink': pink,
      'btn--pink-inverted': pinkInverted,
      'btn--inverted': inverted,
      'btn--full': fullWidth,
    }"
    :type="submit ? 'submit' : 'button'"
    :disabled="disabled"
  >
    <span>{{ text }}</span>
  </button>
</template>

<script>
export default {
  name: "BaseBoton",
  props: {
    text: {
      type: String,
      required: true,
      default: "texto",
    },
    estaCargando: {
      type: Boolean,
      required: false,
      default: false,
    },
    submit: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    green: {
      type: Boolean,
      required: false,
      default: false,
    },
    orange: {
      type: Boolean,
      required: false,
      default: false,
    },
    red: {
      type: Boolean,
      required: false,
      default: false,
    },
    pink: {
      type: Boolean,
      required: false,
      default: false,
    },
    pinkInverted: {
      type: Boolean,
      required: false,
      default: false,
    },
    inverted: {
      type: Boolean,
      required: false,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
/* Default button */
.btn {
  padding: 0.75em 1.5em;
  background: var(--second-color);
  color: #fff;
  font-weight: 600;
  border-radius: 0.5em;
  outline: none;
  border: 2px solid transparent;
  transition: all 300ms ease;
  width: 100%;
  max-width: 12.5em;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: block;
  max-height: 3em;
  font-size: 1em;

  span {
    font-size: 0.875em;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover {
    background: #fff;
    border-color: var(--second-color);

    span {
      color: var(--second-color);
    }
  }

  &--full {
    width: 100%;
    max-width: 100%;
  }

  /* Inverted */
  &--inverted {
    background: #fff;

    span {
      color: var(--second-color);
    }

    &:hover {
      border-color: var(--second-color);

      span {
        color: var(--second-color);
      }
    }

    &.btn--loading {
      border-color: var(--second-color);
      background-color: var(--white-color);

      &::after {
        border-top-color: var(--second-color);
      }
    }
  }

  /* Green button */
  &--green {
    background: var(--green-color);

    &:hover {
      background: #fff;
      border-color: var(--green-color);

      span {
        color: var(--green-color);
      }
    }

    &.btn--loading {
      border-color: var(--green-color);
      background-color: var(--white-color);

      &::after {
        border-top-color: var(--green-color);
      }
    }
  }

  /* Orange button */
  &--orange {
    background: var(--orange-color);

    &:hover {
      background: #fff;
      border-color: var(--orange-color);

      span {
        color: var(--orange-color);
      }
    }

    &.btn--loading {
      border-color: var(--orange-color);
      background-color: var(--white-color);

      &::after {
        border-top-color: var(--orange-color);
      }
    }
  }

  /* Red button */
  &--red {
    background: var(--red-color);

    &:hover {
      background: #fff;
      border-color: var(--red-color);

      span {
        color: var(--red-color);
      }
    }

    &.btn--loading {
      border-color: var(--red-color);
      background-color: var(--white-color);

      &::after {
        border-top-color: var(--red-color);
      }
    }
  }

  /* Pink button */
  &--pink {
    background: var(--first-color);

    &:hover {
      background: #fff;
      border-color: var(--first-color);

      span {
        color: var(--first-color);
      }
    }

    &.btn--loading {
      border-color: var(--first-color);
      background-color: var(--white-color);

      &::after {
        border-top-color: var(--first-color);
      }
    }
  }
  &--pink-inverted {
    background: #fff;

    span {
      color: var(--first-color);
    }

    &:hover {
      border-color: var(--first-color);

      span {
        color: var(--first-color);
      }
    }

    &.btn--loading {
      border-color: var(--first-color);
      background-color: var(--white-color);

      &::after {
        border-top-color: var(--first-color);
      }
    }
  }
}

/* Loading state */
.btn--loading {
  background: #fff;
  border-color: var(--second-color);
  pointer-events: none;

  &::after {
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 1.5625em;
    width: 1.5625em;
    position: absolute;
    border: 4px solid transparent;
    border-top-color: var(--second-color);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: loading 1s linear infinite;
  }

  span {
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s linear;
  }
}

@keyframes loading {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

@media screen and (min-width: 768px) {
 .btn {
  span {
    font-size: 1em;
  }
 }
}
</style>
