import Vue from "vue";
import Vuex from "vuex";
import user from "./user";
import animals from "./animals";
import cart from "./cart";
import { collection, endAt, getDocs, limit, orderBy, query, startAt, where } from "firebase/firestore";
import { firestoreInstance } from "@/firebase/init";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    noticias: [],
    noticiasUsuario: [],
    regiones: []
  },
  mutations: {
    SET_NOTICIAS(state, data) {
      state.noticias = data;
    },
    SET_NOTICIAS_USUARIO(state, data) {
      state.noticiasUsuario = data;
    },
    SET_REGIONES: (state, data) => state.regiones = data,
  },
  actions: {
    async doFetchRegiones({ commit, state }) {
      if (state.regiones.length) return;

      const request = await fetch("/regiones_comunas.json");
      if (!request.ok) throw new Error("Ocurrió un error al obtener las regiones.");

      const data = await request.json();
      return commit("SET_REGIONES", data);
    },
    async doFetchNoticias({ commit, state, rootState }, [docsLimit = 3, reload = false]) {
      if (state.noticias.length && !reload) return;

      const news = await getDocs(query(
        collection(firestoreInstance, "noticias"),
        where("autor.id", "!=", rootState.user.user?.uid),
        limit(docsLimit),
        orderBy("autor.id", "asc"),
        orderBy("creado_el", "desc")
      ));

      return commit("SET_NOTICIAS", news.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })));
    },
    async searchNoticia({ commit, state }, searchString = "") {
      const news = await getDocs(query(
        collection(firestoreInstance, "noticias"),
        where("titulo", ">=", searchString),
        where("titulo", "<=", searchString + "\uf8ff"),
        orderBy("titulo", "asc"),
        orderBy("creado_el", "desc"),
      ));

      return news.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    },
    async doFetchNoticiasUsuario({ commit, state, rootState }, [docsLimit = 3, reload = false]) {
      if (state.noticiasUsuario.length && !reload) return;

      const news = await getDocs(query(
        collection(firestoreInstance, "noticias"),
        where("autor.id", "==", rootState.user.user?.uid),
        limit(docsLimit),
        orderBy("creado_el", "desc")
      ));

      return commit("SET_NOTICIAS_USUARIO", news.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })));
    },
  },
  modules: {
    user,
    animals,
    cart
  },
});

export default store;
