import { authInstance, firestoreInstance } from "@/firebase/init";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  sendEmailVerification,
} from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

const state = {
  user: null,
};

const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },

  CLEAR_USER(state) {
    state.user = null;
  },
};

const actions = {
  async doLogin({ commit, dispatch }, { email, password }) {
    await signInWithEmailAndPassword(authInstance, email, password);
    const user = authInstance.currentUser;

    if (!user) return commit("CLEAR_USER");

    const userDoc = await getDoc(doc(firestoreInstance, "usuarios", user.uid));

    // Create a user in the "usuarios" collection if no exists
    if (!userDoc.exists()) {
      return await dispatch("doCreateProfile", user);
    }
    if (!userDoc.data().habilitado) {
      await dispatch("doLogout");
      throw new Error("No existe una cuenta asociada al correo ingresado.");
    }

    commit("SET_USER", { ...user, ...userDoc.data() });

    // Update user metadata
    return await dispatch("doUpdateProfile", {
      metadata: { ...user.metadata },
    });
  },

  async doLoginProvider({ commit, dispatch }, provider) {
    await signInWithPopup(authInstance, provider);
    const user = authInstance.currentUser;

    if (!user) return commit("CLEAR_USER");

    const userDoc = await getDoc(doc(firestoreInstance, "usuarios", user.uid));

    // Create a user in the "usuarios" collection if no exists
    if (!userDoc.exists()) {
      return await dispatch("doCreateProfile", user);
    }
    if (!userDoc.data().habilitado) {
      await dispatch("doLogout");
      throw new Error("No existe una cuenta asociada al correo ingresado.");
    }

    commit("SET_USER", { ...user, ...userDoc.data() });

    // Update user metadata
    return await dispatch("doUpdateProfile", {
      metadata: { ...user.metadata },
    });
  },

  async doRegister({ dispatch }, { email, password }) {
    const { user } = await createUserWithEmailAndPassword(
      authInstance,
      email,
      password
    );

    await sendEmailVerification(user);

    return await dispatch("doCreateProfile", user);
  },

  async doCreateProfile({ commit }, user) {
    const usersRef = doc(firestoreInstance, "usuarios", user.uid);
    const profileData = {
      animales: [],
      configuracion: {
        notificaciones: true,
      },
      email: user.email,
      estado_registro: "parcial",
      habilitado: true,
      metadata: { ...user.metadata },
      photoURL: user.photoURL,
      privilegio: "base",
    };

    await setDoc(usersRef, profileData);
    return commit("SET_USER", { ...user, ...profileData });
  },

  async doUpdateProfile({ commit }, newData) {
    const userRef = doc(firestoreInstance, "usuarios", state.user.uid);

    // Update user data
    await updateDoc(userRef, newData);
    if ("metadata" in newData)
      return commit("SET_USER", { ...state.user, ...newData });

    // Get user animals
    const animalsQuery = await getDocs(
      query(
        collection(firestoreInstance, "animales"),
        where("id_tutor", "==", state.user.uid)
      )
    );

    // Update owner data of all user animals
    for (const doc of animalsQuery.docs) {
      const dueno = {
        ...doc.data().dueno,
        ...newData,
      };
      await updateDoc(doc.ref, { dueno });
    }
    return commit("SET_USER", { ...state.user, ...newData });
  },

  async doDeleteAccount({ dispatch }) {
    await dispatch("doUpdateProfile", {
      habilitado: false,
    });
    return await dispatch("doLogout");
  },

  async doLogout({ commit }) {
    await signOut(authInstance);
    commit("animals/SET_PETS", [], { root: true });
    commit("animals/SET_ADOPTIONS", [], { root: true });
    commit("SET_NOTICIAS", [], { root: true });
    commit("SET_NOTICIAS_USUARIO", [], { root: true });

    return commit("CLEAR_USER");
  },
};

const getters = {
  isLoggedIn: (state) => {
    return state.user !== null; // Verifica si el usuario está autenticado
  },
  isAdmin: (state) => {
    return state.user?.privilegio === "admin"; // Verifica si el usuario está autenticado
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
