<template>
    <div class="loading">
        <img src="@/assets/cargando.gif" alt="">
        <p>Cargando...</p>
    </div>
</template>
  
<script>
export default {
    name: 'Loading',
}
</script>
  
<style lang="scss" scoped>
.loading {
    height: 100vh;
    height: 100dvh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9;
    background-color: var(--white-color);
    text-align: center;
    overflow: hidden;
    max-width: 100% !important;

    img {
        width: 500px;
        margin-bottom: 100px;
    }

    p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%);
    }
}
</style>
  