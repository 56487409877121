import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/public/Home.vue"),
    meta: {
      title: "Inicio",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      title: "Ingreso",
    },
  },
  {
    path: "/password-recovery",
    name: "password-recovery",
    component: () => import("@/views/auth/PasswordRecovery.vue"),
    meta: {
      title: "Recuperación contraseña",
    },
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: () => import("@/views/auth/SignUp.vue"),
    meta: {
      title: "Registro",
    },
  },
  {
    path: "/sign-up/profile-completion",
    name: "profile-completion",
    component: () => import("@/views/auth/ProfileCompletion.vue"),
    meta: {
      title: "Completa tu perfil",
      requiresAuth: true,
    },
  },
  {
    path: "/terms-conditions",
    name: "terms-conditions",
    component: () => import("@/views/public/TermsConditions.vue"),
    meta: {
      title: "Términos y Condiciones",
    },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("@/views/public/Privacy.vue"),
    meta: {
      title: "Políticas de Privacidad",
    },
  },
  {
    path: "/pets",
    name: "pets",
    component: () => import("@/views/private/pets/Pets.vue"),
    meta: {
      title: "Animales",
      requiresAuth: true,
    },
  },
  {
    path: "/new-pet/:id",
    name: "new-pet",
    component: () => import("@/views/private/pets/NewPet.vue"),
    meta: {
      title: "Nueva Mascota",
      requiresAuth: true,
    },
  },
  {
    path: "/new-adoption",
    name: "new-adoption",
    component: () => import("@/views/private/adoptions/NewAdoption.vue"),
    meta: {
      title: "Nueva Adopción",
      requiresAuth: true,
    },
  },
  {
    path: "/pet-profile/:id",
    name: "pet-profile",
    component: () => import("@/views/private/pets/PetProfile.vue"),
    meta: {
      title: "Perfil Mascota",
    },
  },
  {
    path: "/update-pet/:id",
    name: "update-pet",
    component: () => import("@/views/private/pets/UpdatePet.vue"),
    meta: {
      title: "Actualizar mascota",
      requiresAuth: true,
    },
  },
  {
    path: "/update-adoption/:id",
    name: "update-adoption",
    component: () => import("@/views/private/adoptions/UpdateAdoption.vue"),
    meta: {
      title: "Actualizar adopción",
      requiresAuth: true,
    },
  },
  {
    path: "/adoptions",
    name: "adoptions",
    component: () => import("@/views/private/adoptions/Adoptions.vue"),
    meta: {
      title: "Adopciones",
      requiresAuth: true,
    },
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("@/views/public/Faq.vue"),
    meta: {
      title: "Preguntas Frecuentes",
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/private/Settings.vue"),
    meta: {
      title: "Cuenta",
      requiresAuth: true,
    },
  },
  {
    path: "/feed",
    name: "feed",
    component: () => import("@/views/private/feed/Feed.vue"),
    meta: {
      title: "Novedades",
      requiresAuth: true,
    },
  },
  {
    path: "/read-feed/:id",
    name: "read-feed",
    component: () => import("@/views/private/feed/ReadFeed.vue"),
    meta: {
      title: "Noticia",
      requiresAuth: true,
    },
  },
  {
    path: "/store",
    name: "store",
    component: () => import("@/views/private/store/Products.vue"),
    meta: {
      title: "Productos",
      requiresAuth: true,
    },
  },
  {
    path: "/store/single-product/:id",
    name: "single-product",
    component: () => import("@/views/private/store/SingleProduct.vue"),
    meta: {
      title: "Detalle Producto",
      requiresAuth: true,
    },
  },
  {
    path: "/orders/single-order/:id",
    name: "single-order",
    component: () => import("@/views/private/SingleOrder.vue"),
    meta: {
      title: "Detalle Pedido",
      requiresAuth: true,
    },
  },
  {
    path: "/store/cart",
    name: "cart",
    component: () => import("@/views/private/store/Cart.vue"),
    meta: {
      title: "Solicitud de Placas",
      requiresAuth: true,
    },
  },
  {
    path: "/store/thanks/:id",
    name: "thanks",
    component: () => import("@/views/private/store/Thanks.vue"),
    meta: {
      title: "Gracias por tu compra",
      requiresAuth: true,
    },
  },
  {
    path: "/scan-qr",
    name: "scan-qr",
    component: () => import("@/views/public/ScanQR.vue"),
    meta: {
      title: "Escanear Código QR",
    },
  },

  // Admin Routes
  {
    path: "/master",
    redirect: { name: "admin-dashboard" }
  },
  {
    path: "/master/dashboard",
    name: "admin-dashboard",
    component: () => import("@/views/admin/Dashboard.vue"),
    meta: {
      title: "Dashboard",
      requiresAuth: true,
    },
  },
  {
    path: "/master/users",
    name: "admin-users",
    component: () => import("@/views/admin/Users.vue"),
    meta: {
      title: "Usuarios",
      requiresAuth: true,
    },
  },
  {
    path: "/master/store",
    name: "admin-store",
    component: () => import("@/views/admin/StoreManagement.vue"),
    meta: {
      title: "Productos",
      requiresAuth: true,
    },
  },
  {
    path: "/master/store/new",
    name: "admin-add-product",
    component: () => import("@/views/admin/Store/ProductForm.vue"),
    meta: {
      title: "Agregar producto",
      requiresAuth: true,
    },
  },
  {
    path: "/master/store/:id",
    name: "admin-edit-product",
    component: () => import("@/views/admin/Store/ProductForm.vue"),
    meta: {
      title: "Editar producto",
      requiresAuth: true,
    },
  },
  {
    path: "/master/feed",
    name: "admin-feed",
    component: () => import("@/views/admin/FeedManagement.vue"),
    meta: {
      title: "Novedades",
      requiresAuth: true,
    },
  },
  {
    path: "/master/feed/new",
    name: "admin-add-feed",
    component: () => import("@/views/admin/Feed/FeedForm.vue"),
    meta: {
      title: "Agregar noticia",
      requiresAuth: true,
    },
  },
  {
    path: "/master/feed/:id",
    name: "admin-edit-feed",
    component: () => import("@/views/admin/Feed/FeedForm.vue"),
    meta: {
      title: "Editar noticia",
      requiresAuth: true,
    },
  },
  {
    path: "/master/animals",
    name: "admin-animals",
    component: () => import("@/views/admin/AnimalsManagement.vue"),
    meta: {
      title: "Animales",
      requiresAuth: true,
    },
  },
  {
    path: "/master/animals/new",
    name: "admin-add-animal",
    component: () => import("@/views/admin/Animals/AddAnimal.vue"),
    meta: {
      title: "Agregar animal",
      requiresAuth: true,
    },
  },
  {
    path: "/master/codes",
    name: "admin-qr",
    component: () => import("@/views/admin/CodesManagement.vue"),
    meta: {
      title: "Códigos",
      requiresAuth: true,
    },
  },
  {
    path: "/master/orders",
    name: "admin-orders",
    component: () => import("@/views/admin/OrdersManagement.vue"),
    meta: {
      title: "Pedidos",
      requiresAuth: true,
    },
  },
  // Not Found
  {
    path: "*",
    name: "not-found",
    component: () => import("@/views/public/NotFound.vue"),
    meta: {
      title: "Página no encontrada",
    },
  }

];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }; // Establece el desplazamiento vertical (scroll-y) en 0 al cambiar de ruta
  },
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | RescueMe`;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isLoggedIn = store.getters["user/isLoggedIn"];

  if (requiresAuth && !isLoggedIn) {
    // Usuario no logeado intentando acceder a una ruta protegida
    next({ name: "login" });
  } else if (isLoggedIn) {
    // Usuario logeado intentando acceder a una ruta protegida
    // Rutas no permitidas para usuarios logeados
    const restrictedRoutes = ["home", "sign-up", "login"];

    if (restrictedRoutes.includes(to.name)) {
      // Redirigir a otra página (por ejemplo, "Mis animales") para rutas no permitidas
      next({ name: "pets" });
    } else {
      // Permitir acceso a rutas permitidas para usuarios logeados
      next();
    }
  } else {
    // Rutas sin protección, permitir acceso para usuarios logeados y no logeados
    next();
  }
});

export default router;
