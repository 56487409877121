<template>
  <div
    class="container"
    v-if="isLoading"
    data-aos="zoom-in"
    data-aos-easing="ease-in-out"
  >
    <div class="circular-progress" :style="progressStyles">
      <div class="value-container">
        <h1>{{ currentProgress }}%</h1>
        <p>Cargando...</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      currentProgress: 0,
      progressEndValue: Math.min(this.progress, 100), // Aseguramos que el progreso no exceda 100
      speed: 50,
    };
  },
  computed: {
    progressStyles() {
      return {
        background: `conic-gradient(
          #4d5bf9 ${this.currentProgress * 3.6}deg,
          #cadcff ${this.currentProgress * 3.6}deg
        )`,
      };
    },
  },
  watch: {
    progress(newProgress) {
      this.progressEndValue = Math.min(newProgress, 100); // Aseguramos que el progreso no exceda 100
      this.startProgress();
    },
  },
  mounted() {
    this.startProgress();
  },
  methods: {
    startProgress() {
      const progress = setInterval(() => {
        if (this.currentProgress < this.progressEndValue) {
          this.currentProgress++;
        } else {
          clearInterval(progress);
        }
      }, this.speed);
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100vh;
  background-color: var(--white-color);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  top: 0;
  left: 0;
}
.circular-progress {
  position: relative;
  height: 250px;
  width: 250px;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.circular-progress:before {
  content: "";
  position: absolute;
  height: 84%;
  width: 84%;
  background-color: #ffffff;
  border-radius: 50%;
}
.value-container {
  position: relative;
  font-family: "Poppins", sans-serif;
  color: #231c3d;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
