import { firestoreInstance, storageInstance } from "@/firebase/init";
import { collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import Vue from "vue";

const state = {
  pets: [],
  adoptions: [],
};

const mutations = {
  SET_PETS: (state, data) => state.pets = data,
  SET_ADOPTIONS: (state, data) => state.adoptions = data,
  SET_ANIMAL_STATE(state, { animalId, newState }) {
    const animal = state.pets.find(pet => pet.id === animalId);
    if (animal) {
      animal.estado = newState;
    }
  },
};

const actions = {
  async doFetchAnimal(state, animalId) {
    const animal = await getDoc(doc(firestoreInstance, "animales", animalId));
    if (!animal.exists()) throw new Error("Animal no encontrado");

    return {
      id: animal.id,
      ...animal.data()
    };
  },
  async doFetchPets({ commit }, userId) {
    const pets = await getDocs(
      query(
        collection(firestoreInstance, "animales"),
        where("id_tutor", "==", userId),
        where("habilitado", "==", true)
      )
    );

    return await commit("SET_PETS", pets.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })));
  },
  async doFetchAdoptions({ commit }) {
    const adoptions = await getDocs(query(
      collection(firestoreInstance, "animales"),
      where("estado", "==", "en-adopcion"),
      where("habilitado", "==", true)
    ));

    return await commit("SET_ADOPTIONS", adoptions.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })));
  },
  async doCreateAnimal(state, { animalId, animalData }) {
    const animalRef = doc(firestoreInstance, "animales", animalId);
    return await setDoc(animalRef, animalData);
  },
  async doUpdateAnimal(state, { animalId, animalData }) {
    const animalRef = doc(firestoreInstance, "animales", animalId);
    return await updateDoc(animalRef, animalData);
  },
  async doUploadImages(state, { animalId,  files }) {
    const animalRef = doc(firestoreInstance, "animales", animalId);
    const imagesURL = [];
    for (const file of files) {
      const storageRef = ref(storageInstance, `animales/${animalId}/${file.name}`);
      await uploadBytes(storageRef, file);

      const url = await getDownloadURL(storageRef);
      imagesURL.push(url);
    }
    return await updateDoc(animalRef, { fotos: imagesURL });
  }
};

const getters = {
  getAnimalById: (state) => (animalId) => {
    return state.pets.find(animal => animal.id === animalId);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};