import { firestoreInstance } from "@/firebase/init";
import { collection, doc, getDoc, getDocs, query, updateDoc } from "firebase/firestore";
import Vue from "vue";

const state = {
  products: [],
  productsInCart: [],
  total: 0,
};

const mutations = {
  SET_PRODUCTS: (state, data) => (state.products = data),

  ADD_TO_CART(state, product) {
    const existingProduct = state.productsInCart.find(
      (item) => item.id === product.id
    );

    if (existingProduct) {
      existingProduct.quantity++;
    } else if (product.quantity) {
      state.productsInCart.push({...product});
    } else {
      Vue.set(product, "quantity", 1);
      state.productsInCart.push({...product});
    }

    if (product.descuento && !existingProduct)
      state.total += (product.precio * (1-(product.descuento / 100))) * product.quantity;
    else if (product.descuento && existingProduct)
      state.total += (product.precio * (1-(product.descuento / 100)));
    else if (!product.descuento && !existingProduct)
      state.total += product.precio * product.quantity;
    else
      state.total += product.precio;


    localStorage.setItem("cart", JSON.stringify(state.productsInCart));
  },

  DECREASE_QUANTITY(state, product) {
    const index = state.productsInCart.findIndex(
      (item) => item.id === product.id
    );
    Vue.set(product, "quantity", --product.quantity);
    if (index !== -1) {
      Vue.set(state.productsInCart[index], "quantity", product.quantity);

      if (product.descuento)
        state.total -= product.precio * (1-(product.descuento / 100));
      else
        state.total -= product.precio;
      localStorage.setItem("cart", JSON.stringify(state.productsInCart));
    }

  },

  INCREASE_QUANTITY(state, product) {
    const index = state.productsInCart.findIndex(
      (item) => item.id === product.id
    );
    Vue.set(product, "quantity", ++product.quantity);

    if (index !== -1) {
      Vue.set(state.productsInCart[index], "quantity", product.quantity);

      if (product.descuento)
        state.total += product.precio * (1-(product.descuento / 100));
      else
        state.total += product.precio;

      localStorage.setItem("cart", JSON.stringify(state.productsInCart));
    }
  },

  REMOVE_FROM_CART(state, product) {
    const index = state.productsInCart.findIndex(
      (item) => item.id === product.id
    );

    if (index !== -1) {
      const removedProduct = state.productsInCart.splice(index, 1)[0];

      if (product.descuento)
        state.total -= (removedProduct.precio * (1 - (removedProduct.descuento / 100))) * removedProduct.quantity;
      else
        state.total -= removedProduct.precio * removedProduct.quantity;

      localStorage.setItem("cart", JSON.stringify(state.productsInCart));
    }
  },

  RESET_CART(state) {
    state.productsInCart = [];
    state.total = 0;

    localStorage.removeItem("cart");
  },

  SET_CART(state, cartItems) {
    state.productsInCart = cartItems;

    state.total = cartItems.reduce(
      (total, product) => {
        if (product.descuento)
          return total + (product.precio * (1 - (product.descuento / 100))) * product.quantity;
        return total + product.precio * product.quantity
      },
      0
    );
  },
};

const actions = {
  async doFetchSingleProduct(state, productId) {
    const cartStorage = JSON.parse(localStorage.getItem("cart"));
    const quantity = cartStorage?.find(
      (cartItem) => cartItem.id === productId
    )?.quantity;
    const product = await getDoc(
      doc(firestoreInstance, "productos", productId)
    );
    if (!product.exists()) throw new Error("Producto no encontrado");

    await updateDoc(doc(firestoreInstance, "productos", productId), {
      vistas: (product.data().vistas ?? 0) + 1
    });

    return {
      id: product.id,
      quantity: quantity || 1,
      ...product.data(),
      vistas: (product.data().vistas ?? 0) + 1
    };
  },
  async doFetchProducts({ commit }) {
    const products = await getDocs(
      query(collection(firestoreInstance, "productos"))
    );

    return await commit(
      "SET_PRODUCTS",
      products.docs.map((doc) => ({
        id: doc.id,
        quantity: 1,
        ...doc.data(),
      }))
    );
  },
};
const getters = {
  cartTotalItems: (state) => {
    return state.total;
  },
  cartSubtotalItems: (state) => {
    return state.productsInCart.reduce((prev, product) => prev + product.precio * product.quantity, 0);
  },
  cartDiscounts: (state) => {
    return state.productsInCart.reduce((prev, product) => prev + (product.precio * (product.descuento / 100) * product.quantity), 0);
  },
  getProductById: (state) => (productId) => {
    return state.productsInCart.find((product) => product.id === productId) || state.products.find((product) => product.id === productId);
  },
  productsCount: (state) => state.products.length,
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
