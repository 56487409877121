import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCJOjt07DWxSYu06G6INdQNLsE4qXaWMgE",
  authDomain: "rescueme-39d61.firebaseapp.com",
  projectId: "rescueme-39d61",
  storageBucket: "rescueme-39d61.appspot.com",
  messagingSenderId: "619813405938",
  appId: "1:619813405938:web:81fe2a50eaf22fe5cbbc6b",
};

const firebaseApp = initializeApp(firebaseConfig);
const firestoreInstance = getFirestore(firebaseApp);
const storageInstance = getStorage(firebaseApp);
const authInstance = getAuth(firebaseApp);

export { firestoreInstance, storageInstance, authInstance}
